import { Component, Input } from '@angular/core';
import svgIcons from '../../../../assets/svg/svg-icons';

@Component({
  selector: 'kp-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() avatarUrl: string = 'assets/images/temp/user-placeholder.png';
  @Input() alt: string = '';
  @Input()
  avatarHeight = '46px';
  @Input()
  avatarWidth = '46px';
  @Input()
  placeholderIcon: keyof typeof svgIcons | null = null;
  @Input()
  placeholderColor: string | null = 'white';
}
