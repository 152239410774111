import { Injectable } from '@angular/core';
import { NotifyToastService } from '@shared/components/notify-toast/notify-toast.service';
import { fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { WindowRefService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {
  public isOnline = true;
  private isOnline$ = merge(
    fromEvent(this.windowRefService.nativeWindow, 'offline').pipe(map(() => false)),
    fromEvent(this.windowRefService.nativeWindow, 'online').pipe(map(() => true)),
  );

  constructor(private windowRefService: WindowRefService, private notifyToastService: NotifyToastService) {
    this.isOnline$.subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (isOnline) {
        return this.notifyToastService.dismiss();
      }

      this.notifyToastService.showNotify({
        icon: 'attention',
        description: 'Отсутствует подключение к интернету',
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: Infinity,
      });
    });
  }
}
