<div class="container-avatar">
  <img
    class="circle-img"
    [ngClass]="{ 'circle-img-small': isAvatarFromModal }"
    *ngIf="chat?.avatar; else chatWithNoAvatar"
    [src]="chat?.avatar"
    [style.height]="size"
    [style.width]="size"
  />

  <ng-template #chatWithNoAvatar>
    <div
      class="circle-img"
      [ngClass]="{ 'circle-img-small': isAvatarFromModal }"
      [style.height]="size"
      [style.width]="size"
    >
      <kp-svg
        [ngClass]="{
          'three-people-icon-small-size': isAvatarFromModal,
          'three-people-icon-medium-size': !isAvatarFromModal
        }"
        key="threePersons"
        color="white"
        [size]="size"
      ></kp-svg>
    </div>
  </ng-template>
</div>
