<div
  class="dp-bar-container"
  [ngStyle]="{
    height: dpBarHeight,
    'z-index': isDpActive ? 1 : null
  }"
  (mouseleave)="deactivateDp()"
>
  <kp-svg
    *ngIf="iconBarEnabled; else defaultBar"
    class="dp-bar-icon"
    [key]="barIconKey"
    [height]="barIconHeight"
    [width]="barIconWidth"
    (mouseenter)="activateDp()"
  ></kp-svg>

  <ng-template #defaultBar>
    <div class="dp-bar {{ barClasses }}" [ngStyle]="barStyles" (mouseenter)="activateDp()">
      <div class="dp-bar__title">{{ batTitle }}</div>
      <kp-svg key="calendar" size="16px" cursor="default"></kp-svg>
    </div>
  </ng-template>

  <div [@inOutAnimation] #datePickersWrapper *ngIf="isDpActive" class="date-pickers-wrapper">
    <ng-container *ngTemplateOutlet="datePicker"></ng-container>
  </div>
</div>

<ng-template #datePicker>
  <div
    class="dp-container"
    [ngStyle]="{
      margin: '4px 0'
    }"
  >
    <div class="dp-container__period-options" *ngIf="isPeriodControlsEnabled">
      <button
        class="button button--white"
        [class.button--selected]="selectedPeriod === datePeriod.Today"
        (click)="selectPeriod(datePeriod.Today)"
      >
        Сегодня
      </button>
      <button
        class="button button--white"
        [class.button--selected]="selectedPeriod === datePeriod.LastWeek"
        (click)="selectPeriod(datePeriod.LastWeek)"
      >
        Неделя
      </button>
      <button
        class="button button--white"
        [class.button--selected]="selectedPeriod === datePeriod.LastMonth"
        (click)="selectPeriod(datePeriod.LastMonth)"
      >
        Месяц
      </button>
      <button
        class="button button--white"
        [class.button--selected]="selectedPeriod === datePeriod.LastThreeMonths"
        (click)="selectPeriod(datePeriod.LastThreeMonths)"
      >
        3 Месяца
      </button>
      <button
        class="button button--white"
        [class.button--selected]="selectedPeriod === datePeriod.Custom"
        (click)="selectPeriod(datePeriod.Custom)"
      >
        Выбрать период
      </button>
    </div>

    <div
      class="dp-container__date-pickers"
      [ngClass]="{ 'dp-container__date-pickers--with-time': isTimeEnabled }"
      #datePickersContainer
    >
      <div class="flex">
        <dp-day-time-calendar
          class="date-picker"
          [config]="datePickerConfigFromDate"
          [(ngModel)]="dateFrom"
          [displayDate]="dateFrom"
          (onChange)="dateFromChanged()"
          (onLeftNav)="setStylesForDatePickers()"
          (onRightNav)="setStylesForDatePickers()"
          (onGoToCurrent)="setStylesForDatePickers()"
        ></dp-day-time-calendar>

        <dp-day-time-calendar
          *ngIf="isRangeMode"
          class="date-picker"
          [config]="datePickerConfigToDate"
          [(ngModel)]="dateTo"
          [displayDate]="dateTo"
          (onChange)="dateToChanged()"
          (onLeftNav)="setStylesForDatePickers()"
          (onRightNav)="setStylesForDatePickers()"
          (onGoToCurrent)="setStylesForDatePickers()"
        ></dp-day-time-calendar>
      </div>

      <div class="dp-container__action-controls">
        <button type="button" class="button button--white" (click)="deactivateDp(true)">Отмена</button>
        <button type="button" class="button" (click)="emitChanges()">Применить</button>
      </div>
    </div>
  </div>
</ng-template>
