export enum MembersTypeKey {
  NewMembers = '1',
  MyForumMembers = '2',
  AllMembers = '3',
}

export enum MemberStatusInClubKey {
  Expert = '1',
  Ambassador = '2',
  Moderator = '3',
}
