export const TIME_ZONES = [
  { id: 0, timezone: 'Камчкатка, Чукотка', temporaryOffset: 'UTC/GMT+12', value: 'Asia/Kamchatka' },
  { id: 1, timezone: 'Сахалин', temporaryOffset: 'UTC/GMT+11', value: 'Asia/Sakhalin' },
  { id: 2, timezone: 'Магадан, Верхоянск, Владивосток', temporaryOffset: 'UTC/GMT+10', value: 'Asia/Vladivostok' },
  { id: 3, timezone: 'Якутск', temporaryOffset: 'UTC/GMT+9', value: 'Asia/Yakutsk' },
  { id: 4, timezone: 'Иркутск', temporaryOffset: 'UTC/GMT+8', value: 'Asia/Irkutsk' },
  { id: 5, timezone: 'Красноярск, Кемерово, Норильск', temporaryOffset: 'UTC/GMT+7', value: 'Asia/Krasnoyarsk' },
  { id: 6, timezone: 'Омск', temporaryOffset: 'UTC/GMT+6', value: 'Asia/Omsk' },
  { id: 7, timezone: 'Екатеринбург, Сургут, Оренбург', temporaryOffset: 'UTC/GMT+5', value: 'Asia/Yekaterinburg' },
  { id: 8, timezone: 'Самара, Ижевск', temporaryOffset: 'UTC/GMT+4', value: 'Europe/Samara' },
  { id: 9, timezone: 'Москва Московское время (MSK)', temporaryOffset: 'UTC/GMT+3', value: 'Europe/Moscow' },
  { id: 10, timezone: 'Калининград', temporaryOffset: 'UTC/GMT+2', value: 'Europe/Kaliningrad' },
];

export const MAX_LIMIT = '9999';

export const CURRENCY_CODE = [
  { id: 'RUB', name: 'RUB Российский рубль' },
  { id: 'USD', name: 'USD Доллар США' },
  { id: 'EUR', name: 'EUR Евро' },
];

export const EVENT_BOOKING_TIME = [
  { id: 0, time: '00:00' },
  { id: 1, time: '01:00' },
  { id: 2, time: '02:00' },
  { id: 3, time: '03:00' },
  { id: 4, time: '04:00' },
  { id: 5, time: '05:00' },
  { id: 6, time: '06:00' },
  { id: 7, time: '07:00' },
  { id: 8, time: '08:00' },
  { id: 9, time: '09:00' },
  { id: 10, time: '10:00' },
  { id: 11, time: '11:00' },
  { id: 12, time: '12:00' },
  { id: 13, time: '13:00' },
  { id: 14, time: '14:00' },
  { id: 15, time: '15:00' },
  { id: 16, time: '16:00' },
  { id: 17, time: '17:00' },
  { id: 18, time: '18:00' },
  { id: 19, time: '19:00' },
  { id: 20, time: '20:00' },
  { id: 21, time: '21:00' },
  { id: 22, time: '22:00' },
  { id: 23, time: '23:00' },
  { id: 24, time: '23:59' },
];

export const EVENT_BOOKING_TIME_DAY_VIEW = [
  { id: 0, time: '00:00' },
  { id: 1, time: '01:00' },
  { id: 2, time: '02:00' },
  { id: 3, time: '03:00' },
  { id: 4, time: '04:00' },
  { id: 5, time: '05:00' },
  { id: 6, time: '06:00' },
  { id: 7, time: '07:00' },
  { id: 8, time: '08:00' },
  { id: 9, time: '09:00' },
  { id: 10, time: '10:00' },
  { id: 11, time: '11:00' },
  { id: 12, time: '12:00' },
  { id: 13, time: '13:00' },
  { id: 14, time: '14:00' },
  { id: 15, time: '15:00' },
  { id: 16, time: '16:00' },
  { id: 17, time: '17:00' },
  { id: 18, time: '18:00' },
  { id: 19, time: '19:00' },
  { id: 20, time: '20:00' },
  { id: 21, time: '21:00' },
  { id: 22, time: '22:00' },
  { id: 23, time: '23:00' },
];

// Константа настройки цветовой палитры приложения
export const PRIMARY_COLOR: string = '#5b2f87';

export enum ProductNameTypeKey {
  Kp = 0,
  Opora = 1,
}

export const PRODUCT_NAME: number = ProductNameTypeKey.Kp


