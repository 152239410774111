import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { IUserAuthDto, IUserDto } from '@shared/models/user/dto/user-dto.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IChangedPassword } from '@shared/interfaces/auth/auth-interface';
import { AUTH_REFRESH_TOKEN_LS_KEY } from '@shared/constants/common.const';

interface IAuthDataWithCode {
  code: number;
  phone_number: string;
}

export const REFRESH_URL: string = '/auth/token/refresh/';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private http: HttpService, private httpClient: HttpClient) {}

  authenticate(userData: KeyValue<string>): Observable<IUserAuthDto> {
    return this.http.post<IUserAuthDto>('/auth/login/', userData);
  }

  getUser(): Observable<IUserDto> {
    return this.http.get<IUserDto>('/auth/user/');
  }

  // Запрос на проверку активен ли пользователь
  updateUserActivityTime(userId: number): Observable<void> {
    return this.http.post(`/members/users/${userId}/update-activity-time/`, null);
  }

  //Обновление токена
  refreshToken(): Observable<any> {
    const token = { refresh: localStorage.getItem(AUTH_REFRESH_TOKEN_LS_KEY) };

    return this.http.post<any>(REFRESH_URL, token);
  }

  // Получение кода проверки для входа
  getAuthCode(userNumber: { phone_number: string }): Observable<{ phone_number: string }> {
    return this.http.post<{ phone_number: string }>('/auth/send_otp/', userNumber);
  }

  // Аутентификация через номер телефона и смс код
  authenticationByCode(userData: IAuthDataWithCode): Observable<IAuthDataWithCode> {
    return this.http.post<IAuthDataWithCode>('/auth/login_with_otp/', userData);
  }

  //Google Geo
  getGeoPosition(latitude: any, longitude: any) {
    let getGeopositionApi = 'https://api.bigdatacloud.net/data/reverse-geocode-client';

    getGeopositionApi =
      getGeopositionApi + '?latitude=' + latitude + '&longitude=' + longitude + '&localityLanguage=en';

    return this.httpClient.get(getGeopositionApi);
  }

  // Запрос на смену пароля
  postChangePassword(changePasswortData: IChangedPassword): Observable<IChangedPassword> {
    return this.http.post('/auth/password/change/', changePasswortData);
  }

  // Запрос на ссылку определенного стора, исходя из юзер-агента пользователя
  getMobileStoreLink(params: KeyValue<string>): Observable<{ link: string }> {
    return this.http.get('/store-link/', {
      params: params && new HttpParams({ fromObject: params }),
    });
  }
}
