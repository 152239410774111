export enum ChatWsEventType {
  Error,

  ChatNew,
  ChatUpdated,
  ChatDeleted,

  MessageNew,
  MessageUpdated,
  MessageDeleted,
  ReadAllMessages,

  ChatMarkedAsUnread,
  RemovedFromChat,

  MessagesStateUpdated,

  UserTyping,

  ParticipantJoinChat,
  ParticipantRemovedChat,
  ChatParticipantUpdated,

  MessageReactionUpdate,
  MessageReactionDelete,

  ChatBlocked,
  ChatUnBlocked,

  MessagesReadUntil,
  UnseenReactionsCount,

  FolderUpdated,

  NewInvitationRequest,
  InvitationRequestUpdated,
  PollUpdated,

  Pong,
}

export enum ChatTypeKey {
  Dialog = 0,
  Group = 1,
}

export enum MessageAttachmentType {
  File,
  Media,
}

export enum ChatMessageState {
  Unread, //на бэке имеет аннотацию Delivered, но для нас важно, что сообщение именно не прочитано
  Read,
  Deleted,
  Send,
}

export enum ChatParticipantUserTypeKey {
  User = 0,
  Admin = 1,
}

export enum ChatCurrentUserMembershipState {
  Active,
  Left,
}

export enum ChatRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Avatar = 'avatar',
  Created = 'created',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Owner = 'owner',
  Participants = 'participants',
  ParticipantInfo = 'participant_info',
  Type = 'type',
  Updated = 'updated',
  IsPublic = 'is_public',
  ShowHistory = 'show_history',
  IsInvitationRequired = 'is_invitation_required',
  EnableServiceMsgsFlag = 'enable_service_msgs_flag',

  /**
   * Data fields that exist only in the web interface
   */
  IsDeleting = 'isDeleting',
  IsCurrentUserAdmin = 'isCurrentUserAdmin',
  IsCurrentUserOwner = 'isCurrentUserOwner',
  IsSelected = 'isSelected',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
}

export enum ChatComplaintRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Chat = 'chat',
  FromWhom = 'from_whom',
  Id = 'id',
  Message = 'message',
  State = 'state',

  /**
   * Data fields that exist only in the web interface
   */
  IsDeleting = 'isDeleting',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
}

export enum ChatMessageRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Addressees = 'addressees',
  Author = 'author',
  Chat = 'chat',
  Created = 'created',
  Edited = 'edited',
  Files = 'files',
  Id = 'id',
  IsForwarded = 'is_forwarded',
  Links = 'links',
  MarkedParticipants = 'marked_participants',
  MessageReply = 'message_reply',
  Text = 'text',
  Spam = 'spam',
  State = 'state',
  UserRelatedInfo = 'user_related_info',
  IsService = 'is_service',

  /**
   * Data fields that exist only in the web interface
   */
  IsCurrentUserAuthor = 'isCurrentUserAuthor',
  IsDeleting = 'isDeleting',
  IsSelected = 'isSelected',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
}

export enum ChatMessageFileRelatedFieldKey {
  Created = 'created',
  Id = 'id',
  File = 'file',
  FileSize = 'file_size',
  Filename = 'filename',
  Message = 'message',
  Type = 'type',

  /**
   * Data fields that exist only in the web interface
   */
  Src = 'src',
}

export enum ChatMessageLinkRelatedFieldKey {
  Created = 'created',
  Id = 'id',
  Link = 'link',
  Message = 'message',
}

export enum ChatParticipantRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Chat = 'chat',
  EntryDate = 'entry_date',
  ExitDate = 'exit_date',
  Id = 'id',
  Mutted = 'muted',
  Participant = 'participant',
  Pinned = 'pinned',
  ShowHistoryFlag = 'show_history_flag',
  Type = 'type',
  LastMessage = 'last_message',
  UnreadMessagesCount = 'unread_messages_count',
  User = 'user',

  /**
   * Data fields that exist only in the web interface
   */
  IsDeleting = 'isDeleting',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
}
