<div class="container">
  <div *ngIf="title" class="title">{{ title }}</div>

  <image-cropper
    format="png"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [cropperMinWidth]="230"
    [cropperMinHeight]="230"
    [imageFile]="imageFile"
    (imageCropped)="croppedImageBase64 = $event.base64"
  ></image-cropper>

  <div class="buttons-container buttons-container--self-align-end">
    <button type="button" class="button button--white" (click)="closeDialog()">Отмена</button>
    <button type="button" class="button" (click)="closeDialog(true)">Кадрировать и сохранить</button>
  </div>
</div>
