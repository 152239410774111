export enum NewslineScrollSelector {
  ForumPage = 'forumPageSelector',
}

export enum ElementPostionKey {
  BottomLeft = '1',
  BottomCenter = '2',
  BottomRight = '3',
  TopLeft = '4',
  TopCenter = '5',
  TopRight = '6',
}

export enum DatePeriodKey {
  Today = '0',
  LastWeek = '1',
  LastMonth = '2',
  LastTwoMonths = '3',
  LastThreeMonths = '4',
  AllTime = '5',
  Custom = '6',
}

export enum DatePickerMode {
  Single,
  SingleWithTime,
  Range,
  RangeWithTime,
  RangeWithPeriodControls,
  RangeWithPeriodControlsAndTime,
}

export enum PageMode {
  Create,
  Edit,
}

export enum CommonFilterKey {
  Limit = 'limit',
  Offset = 'offset',
  Ordering = 'ordering',
}

export enum ConfimationMessage {
  SaveChanges = 'Сохранить изменения?',
  CancelChanges = 'Отменить изменения?',
  ContinueWithoutSave = 'Продолжить без сохранения?',

  DeletePost = 'Удалить пост?',
  PinPost = 'Закрепить пост?',
  UnpinPost = 'Открепить пост?',

  CancelForum = 'Отменить форум?',
  PublishForum = 'Опубликовать форум?',
  FinishForum = 'Завершить форум?',

  CancelMeeting = 'Отменить встречу?',
  PublishMeeting = 'Опубликовать встречу?',
  FinishMeeting = 'Завершить встречу?',
}

export enum UserTypeKey {
  Member = '0',
  Staff = '1',
}

export enum UserRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  ActivationDatetime = 'activation_datetime',
  AdditionalIndustries = 'additional_industries',
  Admin = 'admin',
  Age = 'age',
  Avatar = 'avatar',
  Badge = 'badge',
  BusinessExperience = 'business_experience',
  BusinessTurnover = 'business_turnover',
  Childrens = 'childrens',
  City = 'city',
  Company = 'company',
  Country = 'country',
  Curator = 'curator',
  DateOfBirthday = 'date_of_birthday',
  DateJoinedClub = 'date_joined_club',
  Education = 'education',
  Email = 'email',
  FacebookLink = 'facebook_link',
  FirstName = 'first_name',
  FormOfParticipation = 'form_of_participation',
  Forum = 'forum',
  Groups = 'groups',
  Hobbies = 'hobbies',
  Hobby = 'hobby',
  Id = 'id',
  IndustryClubs = 'industry_clubs',
  InstagramLink = 'instagram_link',
  Interests = 'interests',
  IsActive = 'is_active',
  IsCandidate = 'is_candidate',
  IsPrivateEmail = 'is_private_email',
  IsPrivateMobilePhoneNumber = 'is_private_mobile_phone_number',
  IsPrivateWorkPhoneNumber = 'is_private_work_phone_number',
  LastName = 'last_name',
  LastActivityTime = 'last_activity_time',
  MaritalStatus = 'marital_status',
  MeetingCounter = 'meeting_counter',
  MiddleName = 'middle_name',
  MobilePhoneNumber = 'mobile_phone_number',
  NativeCity = 'native_city',
  NotificationsSettings = 'notifications_settings',
  Owner = 'owner',
  Password = 'password',
  Permissions = 'permissions',
  Position = 'position',
  PrimaryIndustries = 'primary_industries',
  ProfileFullness = 'profile_fullness',
  Requests = 'requests',
  CanBeUseful = 'can_be_useful',
  SelfInfo = 'self_info',
  Sex = 'sex',
  SkypeLink = 'skype_link',
  StatusInClub = 'status_in_club',
  TelegramLink = 'telegram_link',
  TargetGroup = 'target_group',
  TwitterLink = 'twitter_link',
  Username = 'username',
  UserType = 'user_type',
  VkLink = 'vk_link',
  WorkPhoneNumber = 'work_phone_number',
  BusinessRegion = 'business_region',
  AgeGte = 'age__gte',
  AgeLte = 'age__lte',
  BusinessExperienceIndustry = 'business_experience_industry',
  Ordering = 'ordering',

  /**
   * Data fields that exist only in the web interface
   */
  AdditionalIndustriesInArrayString = 'additionalIndustriesInArrayString',
  AdditionalIndustriesInString = 'additionalIndustriesInString',
  AllIndustriesInArrayString = 'allIndustriesInArrayString',
  AllIndustriesInString = 'allIndustriesInString',
  BusinessExperienceInArrayString = 'businessExperienceInArrayString',
  ChildrenInArrayShortString = 'childrenInArrayShortString',
  EditPageRoute = 'editPageRoute',
  EducationInArrayString = 'educationInArrayString',
  FirstAndLastName = 'firstAndLastName',
  IsDeleting = 'isDeleting',
  IsMember = 'isMember',
  IsSelected = 'isSelected',
  IsStaff = 'isStaff',
  MenuOptions = 'menuOptions',
  PrimaryIndustriesInArrayString = 'primaryIndustriesInArrayString',
  PrimaryIndustriesInString = 'primaryIndustriesInString',
  Profile = 'profile',
  ProfilePageRoute = 'profilePageRoute',
  SettingsPageRoute = 'settingsPageRoute',
  StatusInClubInArrayString = 'statusInClubInArrayString',
  StatusInClubInArrayStringWithHashSymbol = 'statusInClubInArrayStringWithHashSymbol',

  ProfilePesonalFullness = 'profilePesonalFullness',

  /**
   * Fields are used only for filters, exist in the backend
   */
  BusinessExperienceCity = 'business_experience_city',
  BusinessExperienceCompany = 'business_experience_company',
  BusinessExperiencePosition = 'business_experience_position',
  CreatedAt = 'created_at',
  EducationFaculty = 'education_faculty',
  EducationInstitution = 'education_institution',
  EndMembershipDate = 'end_membership_date',
  IsBlocked = 'is_blocked',
  Fields = 'fields',
  FullName = 'full_name',
  Limit = 'limit',
  NotInChat = 'not_in_chat',
  Offset = 'offset',
  StartMembershipDate = 'start_membership_date',
  YearsOfMembership = 'years_of_membership',

  /**
   * Fields are used only for filters, exist only in the web interface
   */
  Industries = 'industries',
  MembersType = 'members_type',
}

export enum TaskRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Assignee = 'assignee',
  ContentType = 'content_type',
  Created = 'created',
  Description = 'description',
  DueDate = 'due_date',
  Id = 'id',
  IsDeleting = 'isDeleting',
  IsSelected = 'isSelected',
  Title = 'title',
  TypeEvent = 'type_event',
  TypeTask = 'type_task',
  MenuOptions = 'menuOptions',
  Status = 'status',
  ObjectId = 'object_id',
  Updated = 'updated',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
  Search = 'search',

  /**
   * Data fields that exist only in the web interface
   */
  Profile = 'profile',
  TitleFromDialog = 'titleFromDialog',
}
