import { HttpResponse } from '@angular/common/http';

export function prepareAndDownloadFileWithServerData(
  response: HttpResponse<Blob>,
  defaultFileName = 'file',
  defaultType: string = 'application/json',
  filenameRegex: RegExp = /"(.*?)"/,
): Blob {
  const contentDispositionHeader: string = decodeURIComponent(response.headers.get('content-disposition'));
  const contentTypeHeader: string = decodeURIComponent(response.headers.get('content-type'));

  const fileType: string = contentTypeHeader || defaultType;

  let filename: string;

  if (contentDispositionHeader === 'null') {
    filename = defaultFileName;
  } else {
    const splitContentDispositionHeader: string[] = contentDispositionHeader.split(';');

    filename =
      splitContentDispositionHeader.length === 2
        ? filenameRegex.exec(splitContentDispositionHeader[1])[1]
        : splitContentDispositionHeader[2].slice(17);
  }

  const fileURL: string = URL.createObjectURL(response.body);
  const anchor: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.download = filename;
  anchor.href = fileURL;
  anchor.target = '_self';
  anchor.click();
  anchor.remove();

  return new Blob([response.body], { type: fileType });
}
