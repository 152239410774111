<div
  class="avatar-container"
  [style.height]="avatarSize + 'px'"
  [style.width]="avatarSize + 'px'"
  [style.font-size]="fontSize + 'px'"
  [style.color]="fontColor"
  [style.background-image]="avatarUrl ? 'url(' + avatarUrl + ')' : null"
  [style.background-color]="backgroundColor"
>
  <span *ngIf="!avatarUrl">
    {{ userInitials$ | async }}
  </span>

  <ng-content></ng-content>
</div>
