import { Component, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { DialogService, DialogServiceType } from '../../interfaces/dialog/dialog-service';

export const dialogService = 'dialogService';

@UntilDestroy()
@Component({
  selector: 'kp-chat-create-modal',
  template: ``,
  styleUrls: [],
})
export class RoutedModalComponent implements OnInit, OnDestroy {
  private dialogServiceInstance?: DialogService<unknown>;

  constructor(
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public readonly viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnDestroy(): void {
    this.dialogServiceInstance?.dismiss?.();
  }

  public ngOnInit(): void {
    try {
      this.dialogServiceInstance = this.injector.get(
        this.activatedRoute.snapshot.data[dialogService] as DialogServiceType,
      );
    } catch (er) {
      throw Error('data.dialogService should point to DialogServiceType instance');
    }

    this.dialogServiceInstance
      .showDialog(this.viewContainerRef)
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        const urlSegments: UrlSegment[][] = [];
        let route = this.activatedRoute.root.firstChild;

        while (route && route.outlet === 'primary') {
          if (route.snapshot.url.length > 0) {
            urlSegments.push(route.snapshot.url);
          }
          route = route.firstChild;
        }

        const [lastPrimarySegment] = urlSegments.pop();
        const { parameters } = lastPrimarySegment;
        lastPrimarySegment.parameters = {};

        this.router.navigate([
          ...urlSegments,
          { outlets: { primary: [lastPrimarySegment.toString(), parameters], modal: null } },
        ]);

        this.dialogServiceInstance = null;
      });
  }
}
