<div class="avatar" [style.height]="avatarHeight" [style.width]="avatarWidth">
  <img *ngIf="avatarUrl" [src]="avatarUrl" [alt]="alt" />

  <kp-svg
    *ngIf="!avatarUrl && placeholderIcon"
    [key]="placeholderIcon"
    [size]="avatarHeight"
    [color]="placeholderColor"
  ></kp-svg>
</div>

<div class="content-sidebar">
  <div class="content">
    <div class="card-title">
      <ng-content select="[card-title]"></ng-content>
    </div>

    <div class="description">
      <ng-content select="[card-description]"></ng-content>
    </div>
  </div>

  <div class="right-sidebar">
    <ng-content select="[right-sidebar]"></ng-content>
  </div>
</div>