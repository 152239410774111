import {
  Component,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { DomService } from '@core/services/business/utils/dom.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import {
  DATE_FULL,
  DATE_FULL_BACKEND,
  DATE_FULL_WITH_TIME,
  DATE_WITH_TIME_NO_MS_BACKEND,
} from '@shared/constants/common.const';
import { DatePeriodKey, DatePickerMode, ElementPostionKey } from '@shared/enums/keys.enum';
import { DateService } from '@core/services/business/utils/date.service';
import { KeyValue } from '@shared/models/key-value.model';
import { IN_OUT_ANIMATION } from '@shared/animations/in-out.animation';
import { BaseClass } from '../base/base.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { ElementPositionService } from '@core/services/business/utils/element-position.service';
import { AbstractControl } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'kp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [IN_OUT_ANIMATION],
})
export class DatePickerComponent extends BaseClass implements OnChanges, OnInit {
  @ViewChild('datePickersWrapper', { static: false }) datePickersWrapperEl: ElementRef;
  @ViewChild('datePickersContainer') datePickersContainerEl: ElementRef;

  @Input() control: AbstractControl;
  @Input() mode: DatePickerMode;
  @Input() canClear = true;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() dateFromUpdator: moment.Moment | string;
  @Input() dateToUpdator: moment.Moment | string;
  @Input() viewFormat: string;
  @Input() outputFormat: string;
  @Output() dateChangedEvent = new EventEmitter<string>();
  @Output() datesChangedEvent = new EventEmitter<string[]>();

  datePeriod = DatePeriodKey;
  selectedPeriod = DatePeriodKey.Custom;

  datePickerConfig: IDatePickerConfig = {
    locale: 'ru',
    firstDayOfWeek: 'mo',
    monthFormat: 'MMMM YYYY',
    weekDayFormat: 'ddd.',
    showTwentyFourHours: true,
    // minutesInterval: 15,
    showMultipleYearsNavigation: true,
  };

  datePickerConfigFromDate: IDatePickerConfig;
  datePickerConfigToDate: IDatePickerConfig;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dateFromView: moment.Moment;
  dateToView: moment.Moment;
  minDateFrom: moment.Moment;
  maxDateFrom: moment.Moment;
  minDateTo: moment.Moment;
  maxDateTo: moment.Moment;

  @Input() barHeight = '30px';
  @Input() barClasses: string;
  @Input() barStyles: KeyValue<string>;
  @Input() placeholder = 'Выбрать';
  @Input() barIconKey: string;
  @Input() barIconHeight: string;
  @Input() barIconWidth: string;
  isDpActive = false;

  constructor(
    private domService: DomService,
    private renderer: Renderer2,
    private dateService: DateService,
    private elementPositionService: ElementPositionService,
  ) {
    super();
    this.minDateFrom = this.dateService.minDateGlobal;
    this.maxDateTo = this.dateService.maxDateGlobal;

    this._setConfigsMinMax();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate) {
      this.minDateFrom = changes.minDate.currentValue;
    }
    if (changes.maxDate) {
      this.maxDateTo = changes.maxDate.currentValue;
    }
    if (changes.minDate || changes.maxDate) {
      this._setConfigsMinMax();
    }

    if (changes.dateFromUpdator) {
      this.dateFromUpdator = changes.dateFromUpdator.currentValue;

      if (changes.dateFromUpdator.currentValue) {
        if (typeof this.dateFromUpdator === 'string') {
          this.dateFromUpdator = this.dateService.transfromStringToMoment(
            this.dateFromUpdator,
            this.dateWithTimeNoMsBackend,
            false,
            false,
          );
        }

        if (
          this.dateFromUpdator.isSameOrAfter(this.minDateFrom) &&
          this.dateFromUpdator.isSameOrBefore(this.maxDateFrom)
        ) {
          this.dateFrom = this.dateFromView = this.dateFromUpdator;
        } else {
          this.control?.setValue(null, { emitEvent: false });
        }
      } else {
        this.dateFrom = this.dateFromView = this.dateFromUpdator as moment.Moment;
      }
    }

    if (changes.dateToUpdator) {
      this.dateToUpdator = changes.dateToUpdator.currentValue;

      if (changes.dateToUpdator.currentValue) {
        if (typeof this.dateToUpdator === 'string') {
          this.dateToUpdator = this.dateService.transfromStringToMoment(
            this.dateToUpdator,
            this.dateWithTimeNoMsBackend,
            false,
            false,
          );
        }

        if (this.dateToUpdator.isSameOrAfter(this.minDateFrom) && this.dateToUpdator.isSameOrBefore(this.maxDateFrom)) {
          this.dateTo = this.dateToView = this.dateToUpdator;
        } else {
          this.control?.setValue(null, { emitEvent: false });
        }
      } else {
        this.dateTo = this.dateToView = this.dateToUpdator as moment.Moment;
      }
    }
  }

  ngOnInit() {
    if (!this.outputFormat) {
      this.outputFormat = this.isTimeEnabled ? DATE_WITH_TIME_NO_MS_BACKEND : DATE_FULL_BACKEND;
    }
    this.datePickerConfig.format = this.outputFormat;
    this.datePickerConfigFromDate = { ...this.datePickerConfig };
    this.datePickerConfigToDate = { ...this.datePickerConfig };
  }

  selectPeriod(period: DatePeriodKey) {
    this.selectedPeriod = period;

    if (this.selectedPeriod === DatePeriodKey.Custom) {
      return;
    }

    this.dateTo = this.moment();

    switch (this.selectedPeriod) {
      case DatePeriodKey.Today: {
        this.dateFrom = this.dateTo;
        break;
      }
      case DatePeriodKey.LastWeek: {
        this.dateFrom = this.dateService.setMaxTimeToMoment(this.moment().subtract(7, 'days'));
        break;
      }
      case DatePeriodKey.LastMonth: {
        this.dateFrom = this.dateService.setMaxTimeToMoment(this.moment().subtract(1, 'month'));
        break;
      }
      case DatePeriodKey.LastThreeMonths: {
        this.dateFrom = this.dateService.setMaxTimeToMoment(this.moment().subtract(3, 'months'));
        break;
      }
    }

    this._setConfigsMinMax();
    this.setStylesForDatePickers();
  }

  dateFromChanged() {
    this.selectPeriod(DatePeriodKey.Custom);
    this._setConfigsMinMax();

    this.setStylesForDatePickers();
  }

  dateToChanged() {
    this.selectPeriod(DatePeriodKey.Custom);
    this._setConfigsMinMax();

    this.setStylesForDatePickers();
  }

  emitChanges() {
    const changes: string | string[] = this.isSingleMode
      ? this.dateService.transfromMomentToString(this.dateFrom, this.outputFormat)
      : [
          this.dateService.transfromMomentToString(this.dateFrom, this.outputFormat),
          this.dateService.transfromMomentToString(this.dateTo, this.outputFormat),
        ];

    if (this.isSingleMode) {
      this.dateChangedEvent.emit(changes as string);
    } else {
      this.datesChangedEvent.emit(changes as string[]);
    }
    this.control?.markAsDirty();
    this.control?.setValue(changes, { emitEvent: false });

    if (this.dateFrom) {
      this.dateFromView = this.dateFrom;
    }
    if (this.dateTo) {
      this.dateToView = this.dateTo;
    }

    this.deactivateDp();
  }

  private _setConfigsMinMax() {
    this.minDateTo = this.dateFrom ? this.dateFrom : this.minDateFrom;
    this.maxDateFrom = this.dateTo ? this.dateTo : this.maxDateTo;

    if (this.minDateFrom) {
      this.datePickerConfigFromDate = {
        ...this.datePickerConfigFromDate,
        min: this.moment(this.minDateFrom),
      };
    }
    if (this.maxDateFrom) {
      this.datePickerConfigFromDate = {
        ...this.datePickerConfigFromDate,
        max: this.moment(this.maxDateFrom),
      };
    }

    if (this.isRangeMode) {
      if (this.minDateTo) {
        this.datePickerConfigToDate = {
          ...this.datePickerConfigToDate,
          min: this.moment(this.minDateTo ? this.minDateTo : this.dateService.minDateGlobal),
        };
      }
      if (this.maxDateTo) {
        this.datePickerConfigToDate = {
          ...this.datePickerConfigToDate,
          max: this.moment(this.maxDateTo ? this.maxDateTo : this.dateService.maxDateGlobal),
        };
      }
    }
  }

  isElementFitInView() {
    const dpContainerClassName = 'cdk-overlay-pane';
    const dpContainer = this.domService.getElementsByClassName(dpContainerClassName)[0];
    const blockOverflowState = this.domService.getBlockOverflowState(dpContainer);

    if (blockOverflowState.isNotFitInContainer) {
      this.renderer.removeStyle(dpContainer, 'right');
      this.renderer.removeStyle(dpContainer, 'bottom');

      this.renderer.setStyle(
        dpContainer,
        'left',
        `${blockOverflowState.boundingLeft + blockOverflowState.leftCrawledOut - blockOverflowState.rightCrawledOut}px`,
      );
      this.renderer.setStyle(
        dpContainer,
        'top',
        `${blockOverflowState.boundingTop + blockOverflowState.topCrawledOut - blockOverflowState.bottomCrawledOut}px`,
      );
    }
  }

  setStylesForDatePickers() {
    setTimeout(() => {
      if (!this.datePickersContainerEl) {
        return;
      }
      const dpEls = Array.from(
        (this.datePickersContainerEl.nativeElement as Element).getElementsByClassName('date-picker'),
      );

      dpEls.forEach((dpEl) => {
        this._wrapCalendarDayBtnsTextInCustomDiv(dpEl);

        if (this.isSingleMode) {
          this._addCustomClassToSelectedDateSingle(dpEl);
        } else {
          this._addCustomClassToSelectedDateRange(dpEl);
        }
      });
    });
  }

  private _wrapCalendarDayBtnsTextInCustomDiv(dpEl: Element) {
    if (!dpEl) {
      return;
    }

    const dayButtons = Array.from(dpEl.getElementsByClassName('dp-calendar-day'));

    dayButtons.forEach((dayBtn) => {
      const dateDiv = this.renderer.createElement('div');
      const dateDivText = this.renderer.createText(dayBtn.textContent);
      this.renderer.appendChild(dateDiv, dateDivText);
      this.renderer.addClass(dateDiv, 'dp-calendar-day-text');

      dayBtn.textContent = '';
      this.renderer.appendChild(dayBtn, dateDiv);
    });
  }

  private _addCustomClassToSelectedDateSingle(dpEl: Element) {
    if (!dpEl) {
      return;
    }

    const targetDayEl = Array.from(dpEl.getElementsByClassName('dp-calendar-day')).find((dayEl) => {
      const dayDate = this.moment(dayEl.getAttribute('data-date')).startOf('day');
      return this.moment(this.moment(this.dateFrom).startOf('day')).isSame(dayDate);
    });
    if (targetDayEl) {
      this.renderer.addClass(targetDayEl, 'dp-selected-custom');
      this.renderer.addClass(targetDayEl, 'dp-selected-custom-from');
      if (targetDayEl.nextSibling?.nodeType === 1) {
        this.renderer.addClass(targetDayEl.nextSibling, 'dp-first-after-selected');
      }
    }
  }

  private _addCustomClassToSelectedDateRange(dpFrom: Element) {
    if (!dpFrom) {
      return;
    }

    const weeks = Array.from(dpFrom.getElementsByClassName('dp-calendar-week'));

    weeks.forEach((week) => {
      const dayButtons = Array.from(week.getElementsByClassName('dp-calendar-day'));

      dayButtons.forEach((dayBtn, dayBtnIdx) => {
        const dayDate = this.moment(dayBtn.getAttribute('data-date')).startOf('day');

        if (this.moment(this.moment(this.dateFrom).startOf('day')).isSame(dayDate)) {
          this.renderer.addClass(dayBtn, 'dp-selected-custom');
          this.renderer.addClass(dayBtn, 'dp-selected-custom-from');
          const tomorrow = dayButtons[dayBtnIdx + 1];
          const isTomorrowIsDateTo = this.moment(
            this.moment(tomorrow?.getAttribute('data-date')).startOf('day'),
          ).isSame(this.moment(this.dateTo).startOf('day'));

          if (tomorrow && !isTomorrowIsDateTo) {
            this.renderer.addClass(tomorrow, 'dp-first-after-selected');
          }
        } else if (this.moment(dayDate).isSame(this.moment(this.dateTo).startOf('day'))) {
          this.renderer.addClass(dayBtn, 'dp-selected-custom');
          this.renderer.addClass(dayBtn, 'dp-selected-custom-to');
          const yesterday = dayButtons[dayBtnIdx - 1];
          const isYesterdayIsDateFrom = yesterday?.className.includes('dp-selected-custom-from');

          const dayBeforeYesterday = dayButtons[dayBtnIdx - 2];
          const isDayBeforeYesterdayIsDateFrom = dayBeforeYesterday?.className.includes('dp-selected-custom-from');

          if (!isYesterdayIsDateFrom) {
            if (isDayBeforeYesterdayIsDateFrom) {
              this.renderer.addClass(yesterday, 'dp-single-in-range');
            } else if (yesterday) {
              this.renderer.addClass(yesterday, 'dp-last-before-selected');
            }
          } else {
            const isBgBetweenTwoDaysInARowAlreadyAdded = (dayBtn.previousSibling as Element).classList.contains(
              'dp-bg-between-two-days-in-a-row',
            );

            if (!isBgBetweenTwoDaysInARowAlreadyAdded) {
              const bgDiv = this.renderer.createElement('div');
              this.renderer.addClass(bgDiv, 'dp-bg-between-two-days-in-a-row');
              this.renderer.insertBefore(dayBtn.parentNode, bgDiv, dayBtn);
            }
          }
        } else if (
          this.moment(dayDate).isAfter(this.moment(this.dateFrom).startOf('day')) &&
          this.moment(dayDate).isBefore(this.moment(this.dateTo).startOf('day')) &&
          !dayBtn.className.includes('dp-prev-month') &&
          !dayBtn.className.includes('dp-next-month')
        ) {
          this.renderer.addClass(dayBtn, 'dp-selected-range');
        }
      });
    });
  }

  removeCustomClasses() {
    const dpEls = Array.from(
      (this.datePickersContainerEl.nativeElement as Element).getElementsByClassName('date-picker'),
    );
    const customClassesToRemove = [
      'dp-selected-custom',
      'dp-selected-custom-from',
      'dp-selected-custom-to',
      'dp-single-in-range',
      'dp-last-before-selected',
      'dp-bg-between-two-days-in-a-row',
      'dp-selected-range',
    ];

    this.domService.removeClassesFromElementsAndChildren(dpEls, customClassesToRemove);
  }

  get dpBarHeight(): string {
    return this.iconBarEnabled ? this.barIconHeight : this.barHeight;
  }

  get iconBarEnabled(): boolean {
    return !!this.barIconKey && !!this.barIconHeight && !!this.barIconWidth;
  }

  activateDp() {
    this.isDpActive = true;

    setTimeout(() => {
      if (!this.datePickersContainerEl) {
        return;
      }

      this.elementPositionService.setElementPositionRelativeToParent(
        this.datePickersWrapperEl.nativeElement,
        ElementPostionKey.BottomLeft,
      );

      this.setStylesForDatePickers();
      this._mainContainerClickHandler();
    });
  }

  deactivateDp(initValues = false) {
    this.isDpActive = false;

    this.subs.unsubscribe();

    if (initValues) {
      this.dateFrom = this.dateFromView;
      this.dateTo = this.dateToView;
      this._setConfigsMinMax();

      if (this.isPeriodControlsEnabled) {
        this.selectPeriod(DatePeriodKey.Custom);
      }

      if (this.isDpActive) {
        this.removeCustomClasses();
        this.setStylesForDatePickers();
      }
    }
  }

  private _mainContainerClickHandler() {
    this.subs.unsubscribe();
    if (!this.datePickersContainerEl) {
      return;
    }

    this.subs.sink = fromEvent(this.datePickersContainerEl.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._stylesApplyingControllerForSelectedDates();
      });
  }

  private _stylesApplyingControllerForSelectedDates() {
    if (this.dateFrom || this.dateTo) {
      this.setStylesForDatePickers();
    }
  }

  get batTitle(): string {
    let title = this.placeholder;
    let dateFormat = this.isTimeEnabled ? DATE_FULL_WITH_TIME : DATE_FULL;
    if (this.viewFormat) {
      dateFormat = this.viewFormat;
    }

    if (this.isSingleMode && this.dateFromView) {
      title = this.dateFromView.format(dateFormat);
    } else if (this.isRangeMode && (this.dateFromView || this.dateToView)) {
      const dateFromView = this.dateFromView ? this.dateFromView.format(dateFormat) : '...';
      const dateToView = this.dateToView ? this.dateToView.format(dateFormat) : '...';
      title = `${dateFromView} — ${dateToView}`;
    }
    return title;
  }

  get isSingleMode(): boolean {
    return this.mode === DatePickerMode.Single || this.mode === DatePickerMode.SingleWithTime;
  }

  get isRangeMode(): boolean {
    return (
      this.mode === DatePickerMode.Range ||
      this.mode === DatePickerMode.RangeWithTime ||
      this.mode === DatePickerMode.RangeWithPeriodControls ||
      this.mode === DatePickerMode.RangeWithPeriodControlsAndTime
    );
  }

  get isTimeEnabled(): boolean {
    return (
      this.mode === DatePickerMode.SingleWithTime ||
      this.mode === DatePickerMode.RangeWithTime ||
      this.mode === DatePickerMode.RangeWithPeriodControlsAndTime
    );
  }

  get isPeriodControlsEnabled(): boolean {
    return (
      this.mode === DatePickerMode.RangeWithPeriodControls ||
      this.mode === DatePickerMode.RangeWithPeriodControlsAndTime
    );
  }

  get moment() {
    return this.dateService.moment;
  }
}
