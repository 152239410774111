import { Injectable } from '@angular/core';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { KeyValue } from '@shared/models/key-value.model';
import { MeetingHttpService } from '@core/services/api/booking/meeting-http.service';
import {
  IBookingMeetingDto,
  IBookingMeetingForm,
  IBookingMeetingReport,
  IBookingMeetingView,
} from '@shared/interfaces/booking/meeting-interface';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  meetingDates: number[] = [];

  constructor(private meetingHttpService: MeetingHttpService) {}

  getMeetings(params?: KeyValue<string>): Observable<ArrayPayload<IBookingMeetingDto>> {
    return this.meetingHttpService.getMeetings(params);
  }

  getMeetingById(id: number): Observable<IBookingMeetingView> {
    return this.meetingHttpService.getMeetingById(id);
  }

  createMeeting(meeting: IBookingMeetingForm): Observable<IBookingMeetingDto> {
    return this.meetingHttpService.createMeeting(meeting);
  }

  patchMeetingById(id: number, meeting: Partial<IBookingMeetingForm> | FormData): Observable<IBookingMeetingDto> {
    return this.meetingHttpService.patchMeetingById(id, meeting);
  }

  deleteMeeting(id: number): Observable<void> {
    return this.meetingHttpService.deleteMeetingById(id);
  }

  // Используется для получения списка дат, в которых проводятся встречи (для годового календаря)
  getMeetingsForYearCalendar(params?: KeyValue<string>): Observable<ArrayPayload<any>> {
    return this.meetingHttpService.getMeetingsForYearCalendar(params);
  }

  // Отправка полей для выгрузки отчета по бронируемым встречам
  getBookingMeetReport(reportFields: IBookingMeetingReport): Observable<IBookingMeetingReport> {
    return this.meetingHttpService.getBookingMeetReport(reportFields);
  }

  // Бизнес логика
  // Получение информации о наличии встреч в боковом календаре при переключении свитчера в хедере
  getMeetingForCalendar(startGte?: any, startLte?: any) {
    // Если не приходит временной промежуток, взять первое и последнее число текущего месяца
    if (!startGte?.length || !startLte?.length) {
      startGte = moment().startOf('month').format('YYYY-MM-DD');
      startLte = moment().endOf('month').format('YYYY-MM-DD');
    }

    // Фильтр для того, чтобы не отображать отмененные встречи в боковом календаре
    const meetingState = '0,1,3';

    this.getMeetingsForYearCalendar({
      start__gte: startGte,
      start__lte: startLte,
      state: meetingState ? meetingState : '',
    }).subscribe((res) => {
      this.meetingDates = (res as any).meeting_dates.map((el: any) => {
        return moment(el, 'YYYY-MM-DD').format('D');
      });

      // Логика добавления ярлыков на даты, в которых есть мероприятия в календаре
      const elements = document.querySelectorAll('.mat-calendar-body-cell-content');
      elements.forEach((el) => {
        if (this.meetingDates.map((el) => +el).includes(+el.childNodes[0].nodeValue)) {
          // Добавление кружка для отображения наличия мероприятия
          let div = document.createElement('div');
          div.innerHTML = `
              <div style='
              position: absolute;
              left: 30px;
              top: 10px;
              width: 5px;
              height: 5px;
              background: #5b2f87;
              border-radius: 50%;
              '></div>`;

          el.parentElement.append(div);
        } else {
          return '';
        }
      });
    });
  }
}
