import { Injectable } from '@angular/core';
import { IChat } from '@shared/models/messages/view/chat.model';
import { DateService } from '../utils/date.service';
import { IChatDto } from '@shared/models/messages/dto/chat-dto.model';
import { AuthService } from '../auth/auth.service';
import { IChatMessageDto, RetrieveMessageLinkDto } from '@shared/models/messages/dto/chat-message-dto.model';
import {
  IChatMessage,
  RetrieveMessageFile,
  RetrieveMessageLink,
} from '@shared/models/messages/view/chat-message.model';
import { IChatParticipantShort } from '@shared/models/messages/view/chat-participant.model';
import {
  IChatParticipant,
  IChatParticipantDto,
  IChatParticipantShortDto,
} from '@shared/models/messages/dto/chat-participant-dto.model';
import { ChatHttpService } from '@core/services/api/messages/chat-http.service';
import { IUserShortDto } from '@shared/models/user/dto/user-dto.model';
import { orderByTwoFieldsFunction } from '@shared/utils/order-by-two-fields.function';

@Injectable({
  providedIn: 'root',
})
export class ChatUtilsService {
  constructor(
    private dateService: DateService,
    private authService: AuthService,
    private chatHttpService: ChatHttpService,
  ) {}

  transformChatDtoToChatViewFirstLoad(dto: IChatDto): IChat {
    if (!dto) {
      return;
    }
    let view: Partial<IChat> = {};

    view.created = this.dateService.transfromStringToMoment(dto.created);
    view.updated = this.dateService.transfromStringToMoment(dto.updated);

    view = Object.assign(dto, view);
    return view as IChat;
  }

  transformChatDtoToChatView(dto: IChatDto): IChat {
    if (!dto) {
      return;
    }
    let view: Partial<IChat> = {};

    view.created = this.dateService.transfromStringToMoment(dto.created);
    view.updated = this.dateService.transfromStringToMoment(dto.updated);

    view.orderingDate = view.created;

    view.participant_info = dto.participant_info || {};
    if (view.participant_info.last_message) {
      view.participant_info.last_message = this.transformMessageDtoToMessageView(
        view.participant_info.last_message as unknown as IChatMessageDto,
      );
      view.orderingDate = view.participant_info.last_message.created;
    }

    view = Object.assign(dto, view);
    return view as IChat;
  }

  transformMessageDtoToMessageView(dto: IChatMessageDto): IChatMessage {
    if (!dto) {
      return;
    }

    let view: Partial<IChatMessage> = {};

    view.message_reply = this.transformMessageDtoToMessageView(dto?.message_reply as IChatMessageDto);

    view.created = this.dateService.transfromStringToMoment(dto.created);

    if (dto.author) {
      //
      view.author = dto.author;
      view.author.participantProfileLink = `/profile/${dto.author.user.id}`;

      dto.author.user.avatar = dto.author.user.avatar
        ? dto.author.user.avatar
        : 'assets/images/temp/user-placeholder.png';
      //

      view.isCurrentUserAuthor = this.authService.currentUser.id === dto.author.user.id;
    }

    view.files = dto.files?.length
      ? orderByTwoFieldsFunction(dto.files as unknown as RetrieveMessageFile[], 'type', 'id', 'desc')
      : [];

    view = Object.assign(dto, view);

    return view as IChatMessage;
  }

  //Трансвормирует ссылки (бэк --> фронт)
  transformLinksDtoToLinksView(dto: RetrieveMessageLinkDto): RetrieveMessageLink {
    if (!dto) {
      return;
    }
    let view: Partial<RetrieveMessageLink> = {};
    view.created = this.dateService.transfromStringToMoment(dto.created);

    view = Object.assign(dto, view);
    return view as RetrieveMessageLink;
  }

  transformChatParticipantDtoToChatParticipantView(dto: IChatParticipantDto): IChatParticipant {
    if (!dto) {
      return;
    }

    let view: Partial<IChatParticipant> = {};

    // view.entry_date = this.dateService.transfromStringToMoment(dto.entry_date);
    // view.exit_date = this.dateService.transfromStringToMoment(dto.exit_date);

    view = Object.assign(dto, view);
    return view as IChatParticipant;
  }

  transformChatParticipantShortDtoToChatParticipantShortView(
    dto: IChatParticipantShortDto,
    chat: IChatDto,
  ): IChatParticipantShort {
    if (!dto) {
      return;
    }

    let view: Partial<IChatParticipantShort> = {};

    view.user = this.authService.transformUserShortDtoToUserShortView(dto.user as IUserShortDto);
    view.last_message = this.transformMessageDtoToMessageView(dto.last_message);

    view = Object.assign(dto, view);
    return view as IChatParticipantShort;
  }
}
