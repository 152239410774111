import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IChat} from '@shared/models/messages/view/chat.model';

@Component({
  selector: 'kp-avatar-placeholder',
  templateUrl: './avatar-placeholder.component.html',
  styleUrls: ['./avatar-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPlaceholderComponent {
  @Input() chat: IChat;
  @Input() isAvatarFromModal: boolean;
  @Input() size?: number | string;

  constructor() {}
}
