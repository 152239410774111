import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/api/http/http.service';
import { IEnvironment } from '@shared/models/common/environment.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { MembersUserService } from '../members/members-user.service';
import { SelectService } from '../utils/select.service';
import { IUser, IUserAuth, IUserWithProfile } from '@shared/models/user/view/user.model';
import { withLatestFrom } from 'rxjs/operators';
import { PwaService } from '@core/services/business/pwa/pwa.service';
import { Observable } from 'rxjs';

const ENV_VARS_SOURCE_URL = '/assets/environment/environment.json';

/**
 *  Методы в данном сервисе необходимо реализовать через Promise,
 *  так как APP_INITIALIZER, в котором вызывается цепочка методов при старте приложения
 *  ожидает получать именно Promise
 */

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private readonly _apiVersionPath = 'api';

  private readonly currentUser$: Observable<IUserAuth> = this.authService.currentUser$$;

  constructor(
    private http: HttpService,
    private authService: AuthService,
    private pwaService: PwaService,
    private selectService: SelectService,
    private membersUserService: MembersUserService,
  ) {}

  public initializeApp(): Promise<void> {
    return this._initEnvironmentVariables()
      .then(() => this.pwaService.initialize())
      .then(() => this._getInitialUser());
  }

  public initUserProfile(): Promise<void> {
    if (this.authService.isAuthenticated && this.authService.currentUser?.id) {
      return this.membersUserService
        .getUserById(this.authService.currentUser.id)
        .pipe(withLatestFrom(this.currentUser$))
        .toPromise()
        .then(([profile, currentUser]: [IUserWithProfile, IUserAuth]) => {
          currentUser.user.profile = profile;
          this.authService.setCurrentUserState(currentUser);
        })
        .then(() => this.authService.startUserActivityConstantPolling());
    }
  }

  private _initEnvironmentVariables(): Promise<void> {
    return this.http
      .get<IEnvironment>(ENV_VARS_SOURCE_URL, null, null, null)
      .toPromise()
      .then((envVars) => {
        environment.apiUrl = envVars.apiUrl;
        environment.socketUrl = envVars.socketUrl;
        environment.apiVersionPath = this._apiVersionPath;
      })
      .then(() => this.selectService.initOptionsSourcesConstants());
  }

  private _getInitialUser(): Promise<void> {
    if (this.authService.isAuthenticated) {
      return (
        this.authService
          .getUser()
          .pipe(withLatestFrom(this.currentUser$))
          .toPromise()
          .then(([user, currentUser]: [IUser, IUserAuth]) =>
            this.authService.setCurrentUserState({
              ...currentUser,
              user,
            }),
          )
          // Завершаем поток в случае ошибки цепочки
          .catch(() => {})
          .then(() => this.initUserProfile())
      );
    }
  }
}
