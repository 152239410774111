import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '@core/services/business/utils/file.service';
import { KeyValue } from '@shared/models/key-value.model';

export interface CropperResult {
  croppedImageFile: File;
  src: string;
}

@Component({
  selector: 'kp-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent {
  title: string;
  imageFile: File;
  croppedImageBase64: string;

  constructor(
    private dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: KeyValue<any>,
    private fileService: FileService,
  ) {
    this._initVarsFromData(data);
  }

  private _initVarsFromData(data: KeyValue<any>) {
    if (!data) {
      return;
    }

    this.title = data.title;
    this.imageFile = data.imageFile;
  }

  closeDialog(withResult = false) {
    const result: CropperResult = {
      croppedImageFile: this.fileService.convertBase64ToFile(this.croppedImageBase64, this.imageFile.name, this.imageFile.type),
      src: this.croppedImageBase64,
    };

    this.dialogRef.close(withResult ? result : null);
  }
}
